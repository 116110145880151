import { useState, useEffect } from "react";
import cx from "classnames";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "@remix-run/react";
import { useSelector, useDispatch } from "react-redux";

import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import { useToast, Button } from "~/components/vendorUI";
import { useFeatureFlags } from "~/hooks/featureFlags";
import fetchExpLeadDetails from "~/pages/PPL/actions/expansion_leads/fetch_exp_lead_details";
import purchaseExpansionLead from "~/pages/PPL/actions/expansion_leads/purchase_expansion_lead";
import setIsLoading from "~/pages/PPL/actions/set_is_loading";
import withLoader from "~/pages/PPL/utils/with_loader";
import { useTranslation } from "~/utils";

import LeadColData from "../components/LeadColData";
import PurchaseModal from "../components/PurchaseModal";
import s from "./pre_purchase.module.scss";

const getLeadColData = (useNewLeadTerms = false) => [
  {
    title: "PPL_LEADS_DETAILS_LABEL_INSTRUCTIONS",
    key: "follow_up_notes",
  },
  {
    title: "PPL_LEADS_DETAILS_LABEL_REASONS",
    nested: true,
    getNestedValue: (profile) => profile?.expansion_reasons?.join(" "),
  },
  {
    title: "PPL_LEADS_DETAILS_LABEL_JOB-TITLE",
    key: "job_title",
  },
  {
    title: useNewLeadTerms
      ? "PPL_LEADS_DETAILS_LABEL_INDUSTRY"
      : "PPL_LEADS_DETAILS_LABEL_SEGMENT",
    key: useNewLeadTerms ? "industry_name" : "segment",
  },
  {
    title: "PPL_LEADS_DETAILS_LABEL_USERS",
    nested: true,
    getNestedValue: (profile) => profile?.size_types?.["Number of Users"],
  },
  {
    title: "PPL_LEADS_DETAILS_LABEL_REASONS-SHOPPING",
    key: "reasons_for_shopping",
  },
  {
    title: "PPL_LEADS_DETAILS_LABEL_EVALUATED",
    key: "who_evaluated",
  },
];

const PrePuchaseConfirm = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const { selectedPPLCampaign } = useCurrentUser();
  const { expansionLeadID } = useParams();
  const lead = useSelector(
    (state) => state.ppl.expansionLeads[expansionLeadID],
  );
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchExpLeadDetails(expansionLeadID));
  }, [selectedPPLCampaign]);

  useEffect(() => {
    if (lead?.error) {
      const errorMap = {
        404: "PPL_EXPANSION-LEADS_LEAD-NOT-FOUND",
        422: "PPL_EXPANSION-LEADS_LEAD-EXPIRED",
        500: "ERROR_MODAL_TITLE",
      };
      addToast(t(errorMap[lead.error]), { type: "error" });
      navigate("/ppl/expansionleads");
    }
  }, [lead?.error]);

  const [purchaseStatus, setPurchaseStatus] = useState("CLOSED");
  const [purchasedLeadId, setPurchasedLeadId] = useState("");
  const useNewLeadTerms = isFeatureEnabled("ppl-lead-new-terminologies");

  const handlePurchase = async () => {
    // purchase for ID ${expansionLeadID} - API call
    dispatch(setIsLoading(true));
    const res = await purchaseExpansionLead(expansionLeadID);

    if (res.data?.expansion_lead?.lead_id) {
      setPurchaseStatus("SUCCESS");
      setPurchasedLeadId(res.data.expansion_lead.lead_id);
    } else {
      if (res.isInvalid) setPurchaseStatus("UNAVAILABLE");
      else setPurchaseStatus("ERROR");
    }
    dispatch(setIsLoading(false));
  };

  return (
    <div className={s.container}>
      <h1 className="gdm-title">
        {t("PPL_EXPANSION-LEADS_PURCHASE-CONFIRM_TITLE")}
      </h1>
      <Link
        className="gdm-link-default"
        data-gtm="pplexpleads-viewallleads"
        to="/ppl/expansionleads"
      >
        {t("PPL_EXPANSION-LEADS_ACTION_GO-BACK")}
      </Link>
      <div className={cx(s.between, "gdm-m-top-lg")}>
        <span className="gdm-paragraph-sm">
          {t("PPL_EXPANSION-LEADS_PURCHASE-CONFIRM_PREFACE")}
        </span>
        <Button
          onClick={handlePurchase}
          data-tracking="ppl_expansion_leads_detail_purchase"
        >
          {t("PPL_EXPANSION-LEADS_PURCHASE-CONFIRM_CTA")}
        </Button>
      </div>

      <div className="gdm-row">
        <LeadColData
          lead={lead || {}}
          colData={getLeadColData(useNewLeadTerms)}
          colWidth={12}
        />
      </div>
      <PurchaseModal
        type={purchaseStatus}
        onClosed={() => setPurchaseStatus("CLOSED")}
        leadID={purchasedLeadId}
      />
    </div>
  );
};

export default withLoader(PrePuchaseConfirm);
