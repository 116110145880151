import { useEffect } from "react";
import cx from "classnames";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "@remix-run/react";
import { useSelector, useDispatch } from "react-redux";

import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import withLoader from "~/pages/PPL/utils/with_loader";
import fetch_exp_lead_details from "~/pages/PPL/actions/expansion_leads/fetch_exp_lead_details";
import { useFeatureFlags } from "~/hooks/featureFlags";

import LeadColData from "../components/LeadColData";
import s from "./pre_purchase.module.scss";
import { formatDate } from "~/pages/PPL/utils/date";
import { useTranslation } from "~/utils";
import { useToast, Button } from "~/components/vendorUI";
import { css } from "ui/css";

const companyDetailsTitleI18n = (key) =>
  ({
    "Number of Employees": "PPL_LEADS_DETAILS_LABEL_EMPLOYEES",
    "Size (Annual Revenue)": "PPL_LEADS_DETAILS_LABEL_ANNUAL-REVENUE",
    "Size (Operating Budget)": "PPL_LEADS_DETAILS_LABEL_OPERATING_BUDGET",
    "Annual Revenue": "PPL_LEADS_DETAILS_LABEL_ANNUAL-REVENUE",
    "Number of Users": "PPL_LEADS_DETAILS_LABEL_USERS",
    "Number of Users (Learners)": "PPL_LEADS_DETAILS_LABEL_USERS",
    "Number of Doctors": "PPL_LEADS_DETAILS_LABEL_NUMBER_OF_DOCTORS",
  })[key] || key;

const getLeadConfig = (useNewLeadTerms = false) => [
  {
    heading: "PPL_LEADS_DETAILS_CONTACT_TITLE",
    leadData: [
      [
        {
          title: "PPL_LEADS_DETAILS_LABEL_INSTRUCTIONS",
          key: "follow_up_notes",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_COMPANY-NAME",
          key: "company",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_CONTACT-NAME",
          key: "contactName",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_JOB-TITLE",
          key: "job_title",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_OFFICE-PHONE",
          key: "phone",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_TIMEZONE",
          key: "time_zone",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_EMAIL",
          key: "email",
        },
      ],
      [
        {
          title: "PPL_LEADS_DETAILS_LABEL_WEBSITE",
          key: "website",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_ADDRESS",
          key: "address",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_CITY",
          key: "city",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_STATE",
          key: "state",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_ZIP",
          key: "zip",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_COUNTRY",
          key: "country",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_TIMESTAMP",
          nested: true,
          getNestedValue: (profile) =>
            profile?.buyer_signed_up_at &&
            formatDate(profile.buyer_signed_up_at, "en-us", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: false,
              timeZoneName: "short",
            }),
          toolTip: true,
          toolTipKey: "PPL_LEADS_DETAILS_LABEL_TIMESTAMP_TOOLTIP",
        },
      ],
    ],
  },
  {
    heading: "PPL_LEADS_DETAILS_COMPANY-PROFILE_TITLE",
    leadData: [
      [
        {
          title: useNewLeadTerms
            ? "PPL_LEADS_DETAILS_LABEL_MARKET"
            : "PPL_LEADS_DETAILS_LABEL_INDUSTRY",
          key: useNewLeadTerms ? "market_name" : "industry",
        },
        {
          title: useNewLeadTerms
            ? "PPL_LEADS_DETAILS_LABEL_INDUSTRY"
            : "PPL_LEADS_DETAILS_LABEL_SEGMENT",
          key: "industry_name",
        },
      ],
    ],
  },
  {
    heading: "PPL_LEADS_DETAILS_PROJECT-OVERVIEW_TITLE",
    leadData: [
      [
        {
          title: "PPL_LEADS_DETAILS_LABEL_APPLICATIONS-NEEDED",
          key: "applications",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_KEY-FEATURES-NEEDED",
          key: "key_features_needed",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_DEPLOYMENT",
          key: "deployment",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_CURRENTLY-USING",
          key: "currently_using",
        },
      ],
      [
        {
          title: "PPL_LEADS_DETAILS_LABEL_REASONS-SHOPPING",
          key: "reasons_for_shopping",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_EVALUATED",
          key: "who_evaluated",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_PRICE-EXPECTATIONS",
          key: "price_expectations",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_TIMEFRAME",
          key: "timeframe",
        },
      ],
    ],
  },
  {
    heading: "PPL_LEADS_DETAILS_NEXT-STEPS_TITLE",
    leadData: [
      [
        {
          title: "PPL_LEADS_DETAILS_LABEL_REQUEST",
          key: "request",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_CONTACT-NOTES",
          key: "contact_notes",
        },
      ],
      [
        {
          title: "PPL_LEADS_DETAILS_LABEL_PRODUCT",
          key: "product_name",
        },
        {
          title: "PPL_LEADS_DETAILS_LABEL_QUALIFIED-BY",
          key: "qualified_by",
        },
      ],
    ],
  },
];

const PrePuchaseLeadDetails = () => {
  const { t } = useTranslation();
  const { selectedPPLCampaign } = useCurrentUser();
  const { expansionLeadID } = useParams();
  const lead = useSelector(
    (state) => state.ppl.expansionLeads[expansionLeadID],
  );
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    dispatch(fetch_exp_lead_details(expansionLeadID));
  }, [selectedPPLCampaign]);

  useEffect(() => {
    if (lead?.error) {
      const errorMap = {
        404: "PPL_EXPANSION-LEADS_LEAD-NOT-FOUND",
        422: "PPL_EXPANSION-LEADS_LEAD-EXPIRED",
        500: "ERROR_MODAL_TITLE",
      };
      addToast(t(errorMap[lead.error]), { type: "error" });
      navigate("/ppl/expansionleads");
    }
  }, [lead?.error]);

  const useNewLeadTerms = isFeatureEnabled("ppl-lead-new-terminologies");
  const leadConfig = getLeadConfig(useNewLeadTerms);

  return (
    <div className={cx("gdm-grid", s.container)}>
      <h1 className="gdm-title">{t("PPL_LEADS_DETAILS_TITLE")}</h1>
      <Link
        className="gdm-link-default"
        data-gtm="pplexpleads-viewallleads"
        to="/ppl/expansionleads"
      >
        {t("PPL_EXPANSION-LEADS_ACTION_GO-BACK")}
      </Link>
      {/* 1st row with button */}
      <div className={cx(s.between, "gdm-m-top-lg")}>
        <span className="gdm-heading-lg">{t(leadConfig[0].heading)}</span>
        <Button>
          <Link
            className={css({ textDecoration: "none", color: "white" })}
            to={`/ppl/expansionleads/${expansionLeadID}/purchase`}
          >
            {t("PPL_EXPANSION-LEADS_ACTION_PURCHASE-LEAD")}
          </Link>
        </Button>
      </div>
      <div className="gdm-flex gdm-row">
        {leadConfig[0].leadData.map((colData, idx) => (
          <LeadColData
            key={`row1${
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              idx
            }`}
            colData={colData}
            lead={lead || {}}
          />
        ))}
      </div>
      {/* 1st record ends */}
      {leadConfig.slice(1).map(({ heading, leadData }) => {
        let data = leadData;

        if (heading === "PPL_LEADS_DETAILS_COMPANY-PROFILE_TITLE") {
          const dynamicDetails = lead?.size_types ?? {};
          data = [
            ...data,
            Object.entries(dynamicDetails).map(([title, detail]) => ({
              value: detail,
              title: t(companyDetailsTitleI18n(title, { defaultValue: title })),
            })),
          ];
        }

        return (
          <div key={heading} className="gdm-row gdm-m-top-lg gdm-block">
            <div className="gdm-col">
              <span className="gdm-heading-lg">{t(heading)}</span>
            </div>
            <div className="gdm-flex">
              {data.map((colData, idx) => (
                <LeadColData
                  key={`row2${
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    idx
                  }`}
                  colData={colData}
                  lead={lead || {}}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withLoader(PrePuchaseLeadDetails);
